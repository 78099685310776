import {
	EFxIdSdkAdapterInfoSocialCapability,
	FxIdSdkAdapterSocialSettingDefault,
	FxIdSdkBaseAdapter,
	IExportedFxIdSdkMethod,
	IFxIdSdkAdapterBuyProductRequest,
	IFxIdSdkAdapterBuyProductResponse,
	IFxIdSdkAdapterInfoSocial,
	IFxIdSdkAdapterSocialSettings,
	IFxIdSdkAdapterStatEventRequest,
	IFxIdSdkAdapterStatInitializeRequest,
	IFxIdSdkGetFriendsResult
} from "./FxIdSdkBaseAdapter";
import OpenApiClient from "../Api/OpenApiClient";
import {
	FxIdApplicationStoreCreatePaymentHandlerEmbeddingType,
	FxIdDomainStoreEnumsSupportedWebPublishingPlatform,
	FxGamesLibWebPublicDataBase,
	FxIdWebFeaturesStoreCreatePaymentRequest
} from "../Api/gen";
import { userStore } from "../Stores";
import {
	AgRuCallback,
	AgRuSdk,
	AgRuSdkEvents,
	AgRuSdkMethods,
	AgRuSdkOptions,
	AgRuSdkResponse,
	AgRuUser
} from "@agru/sdk";
import i18next from "i18next";
import { getAuthToken, setAuthToken } from "../Api/Auth";
import { deferred } from "./FxIdSdkUtils";
import {
	FxIdStats,
	FxIdStatsTutorialCompleted,
	FxIdStatsTutorialSkip,
	FxIdStatsTutorialStart,
	FxIdStatsTutorialStep
} from "./FxIdStats";

export class FxIdSdkAdapterForAbsoluteGames extends FxIdSdkBaseAdapter {
	private sdk?: AgRuSdk;
	private currentPlayer?: AgRuUser & { game_sid: string };
	private buyProductResolve?: (
		value: PromiseLike<IFxIdSdkAdapterBuyProductResponse> | IFxIdSdkAdapterBuyProductResponse
	) => void;
	private buyProductReject?: (
		value: PromiseLike<IFxIdSdkAdapterBuyProductResponse> | IFxIdSdkAdapterBuyProductResponse
	) => void;
	private fxIdStats: FxIdStats;

	constructor(
		protected exportedSdk: IExportedFxIdSdkMethod,
		protected game: string,
		protected config: FxGamesLibWebPublicDataBase
	) {
		super(exportedSdk);
		this.fxIdStats = new FxIdStats();
	}

	SocialSettings(): Promise<IFxIdSdkAdapterSocialSettings> {
		return Promise.resolve({ ...FxIdSdkAdapterSocialSettingDefault, ui: { disabled: true } });
	}

	RegisterShareHandlers(): Promise<void> {
		return Promise.resolve();
	}

	async BuyProduct(request: IFxIdSdkAdapterBuyProductRequest): Promise<IFxIdSdkAdapterBuyProductResponse> {
		const agRuSdk = this.sdk!;

		if (agRuSdk.options.guest === "true") {
			log.info("Player is guest. Authenticating");
			const [[result]] = (await agRuSdk.authorizeAndWait()) as unknown as [boolean[]];
			if (result) {
				log.info("Player is authorized");
			} else {
				log.warn("Player has not authorized");
			}
			// Наш обработчик события AgRuSdkEvents.OptionsUpdates перезагрузит страницу если игрок поменялся

			return { error: "Not authorized" };
		} else {
			log.info("Player authenticated");

			const paymentRequest: FxIdWebFeaturesStoreCreatePaymentRequest = {
				Token: getAuthToken()!,
				Game: this.game,
				Sku: request.sku,
				WebPublishingPlatform: FxIdDomainStoreEnumsSupportedWebPublishingPlatform.AbsoluteGames,
				EmbeddingType: FxIdApplicationStoreCreatePaymentHandlerEmbeddingType.Embed,
				ProductDescriptionHint: request.productDescriptionHint,
				ProductNameHint: request.productNameHint,
				AbsoluteGames: {
					// Хотя дока и типы говорят что appId это число, в объекте приходит строчка
					AppId: parseInt(agRuSdk.options.app_id!.toString()),
					AuthKey: agRuSdk.options.auth_key as string,
					Debug: agRuSdk.options.debug === "true",
					GameSid: agRuSdk.options.game_sid as string,
					PlayerId: agRuSdk.options.player_id as string,
					Guest: this.sdk?.options.guest === "true",
					Invite: this.sdk?.options.invite as string
				}
			};

			if (request.developerPayload != null) {
				paymentRequest.DeveloperPayload = {
					MerchantDeveloperPayload: request.developerPayload
				};
			}

			const createPaymentResult =
				await OpenApiClient.Store.fxIdWebFeaturesStoreCreatePaymentEndpoint(paymentRequest);

			log.info("Received result from server: %o", createPaymentResult);

			const { promise, resolve, reject } = deferred<IFxIdSdkAdapterBuyProductResponse>();
			this.buyProductResolve = (data: any) => {
				resolve({
					...data,
					transactionId: createPaymentResult.TransactionId,
					stats: {
						currency: createPaymentResult.OrderProduct.Currency ?? "",
						currencyAmount: createPaymentResult.OrderProduct.Price ?? 0
					}
				});
			};
			this.buyProductReject = reject;

			let [data, error] = await this.sdk!.showPayment(createPaymentResult.AbsoluteGames!.Token);

			log.info("Received payment result from ag: %o", data);

			// У АГ была ошибка в апи 1.0.x - там двойной массив
			if (Array.isArray(data as unknown)) {
				log.info("Unwrapping ag result");
				const dataHack = data as unknown as [AgRuSdkResponse[AgRuSdkMethods.ShowPayment], Error | null];
				data = dataHack[0];
				error = dataHack[1];
			}

			if (error) {
				log.error(error);

				return { error: error.message };
			}

			// // If the user closed the payment popup and there is no details about the payment, then run this code.
			// if (data.info == null) {
			// 	log.info("User closed payment window");
			// 	return { error: "User closed payment window" };
			// }
			//
			// if (data.info.invoice == null) {
			// 	log.info("Invoice is empty");
			// 	return { error: "Invoice is empty" };
			// }

			log.info("Waiting for AgRuSdkMethods.ShowPayment callback");

			return promise;
		}
	}

	// private async VerifyPurchase(purchase: YandexGamesPaymentsPurchase, yandexPayments: YaGamesPayments) {

	// 	log.info("Verifying payment on server %o", purchase);
	// 	const response = await OpenApiClient.YandexGames.fxIdWebFeaturesStoreYandexGamesPurchaseEndpoint(this.game, {
	// 		YandexSignedData: purchase.signature,
	// 		YandexGetPurchasesRequest: false
	// 	});
	//
	// 	log.info("Received response from server: %o", response);
	//
	// 	const responsePurchase = response.TokenSuccesses[0];
	//
	// 	if (!responsePurchase.Success) {
	// 		log.info("Purchase %s not validated", responsePurchase.PurchaseToken);
	// 	}
	//
	// 	log.info("Purchase %s validated", responsePurchase.PurchaseToken);
	// 	yandexPayments.consumePurchase(responsePurchase.PurchaseToken);
	// }
	async GetSocialInfo(): Promise<IFxIdSdkAdapterInfoSocial> {
		const userInfo = await this.GetPlayer();

		return Promise.resolve({
			social: FxIdDomainStoreEnumsSupportedWebPublishingPlatform.AbsoluteGames,
			paymentsAvailable: true,
			userId: userInfo.id.toString(),
			displayName: userInfo.username,
			photo: {
				url: userInfo.avatar
			},
			capabilities: [EFxIdSdkAdapterInfoSocialCapability.AdsAvailable]
		});
	}

	async GetPlayer(): Promise<AgRuUser> {
		log.info("Getting player from ag.ru");
		let [data, error] = await this.sdk!.getUsers([this.sdk!.options.player_id!]);

		log.info("Received data from ag: %o", data);

		// Баг в апи агру
		if (Array.isArray(data)) {
			data = data[0] as unknown as AgRuUser[];
			error = data[1] as unknown as Error | null;
		}

		if (error != null) {
			log.error(error);
			throw error;
		}

		log.info("Player from ag: %o", data);

		return data[0];
	}

	async Initialize(): Promise<void> {
		log.info("Initializing AG.RU adapter");

		log.info("Initializing AG.RU sdk");
		this.sdk = new AgRuSdk();

		log.info("Initialized ag.ru sdk with options: %o", this.sdk!.options);

		let isPaymentVisible = false;

		this.sdk!.on(AgRuSdkMethods.ShowPayment, ({ info, status }) => {
			isPaymentVisible = status;

			// If the payment popup is on screen, then run this code.
			if (status) {
				log.info("Payment window visible");
				return;
			}

			// If the user closed the payment popup and there is no details about the payment, then run this code.
			if (info == null) {
				log.info("User closed payment window");
				this.buyProductReject?.call(this, { error: "User closed payment window" });
				return;
			}

			// If the popup was closed and the payment details are present, check the payment id.
			if (info.invoice) {
				this.buyProductResolve?.call(this, { transactionId: info.invoice.toString() });
				return;
				// check on the server, if the payment has been successful.
			}

			this.buyProductReject?.call(this, { error: "Unknown state" });
		});

		this.sdk!.on(AgRuSdkMethods.ShowCampaign, (options) => {
			const { status, type, reward } = options;
			log.info("Received AgRuSdkMethods.ShowCampaign: %o", options);

			if (!window.FxIdSdk) {
				return;
			}

			if (!status) {
				if (type === "rewarded") {
					if (reward) {
						window.FxIdSdk.DispatchAdsFinished();
					} else {
						window.FxIdSdk.DispatchAdsFailed();
					}
				} else {
					// Другие типы не поддерживаются.
					window.FxIdSdk.DispatchAdsFailed();
				}
			}
		});

		this.sdk.on(AgRuSdkEvents.OptionsUpdates, (newOptions) => {
			log.info("Received AgRuSdkEvents.OptionsUpdates from ag with options: %o", newOptions);

			void this.OnAgRuSdkOptionsUpdate(newOptions);
		});

		await this.UpdateCurrenUserAndUserStore();
	}

	async UpdateCurrenUserAndUserStore() {
		log.info("Getting player");
		this.currentPlayer = { ...(await this.GetPlayer()), game_sid: this.sdk!.options.game_sid! };

		userStore.getState().updateUserId(String(this.currentPlayer.id));
		userStore
			.getState()
			.updateDisplayName(
				`#${this.currentPlayer.username ?? this.currentPlayer.full_name ?? this.currentPlayer.id}`
			);
	}

	async StoreCurrency(): Promise<string | undefined> {
		return Promise.resolve(undefined);
	}

	private _statInitialize = false;

	async StatInitialize(request: IFxIdSdkAdapterStatInitializeRequest): Promise<void> {
		const devToDev = this.config.Statistics?.DevToDev;
		if (devToDev == null) {
			log.warn("No devtodev configuration");
			return;
		}

		if (!this._statInitialize) {
			log.info("Initializing devtodev");
			await new Promise<void>((resolve) => {
				const s = document.createElement("script");
				s.type = "text/javascript";
				s.src = "//cdn.devtodev.com/sdk/web/v2/devtodevsdk.js";
				s.addEventListener(
					"load",
					(e) => {
						log.info("DevToDev loaded");
						this._statInitialize = true;
						resolve();
					},
					false
				);
				const head = document.getElementsByTagName("head")[0];
				head.appendChild(s);

				log.info("DevToDev added");
			});
		} else {
			log.info("DevToDev initialized");
		}

		log.info("Initializing devtodev");

		const initData = {
			userId: request.userId,
			currentLevel: request.userLevel,
			applicationVersion: request.buildVersion,
			trackingAvailability: request.trackingEnabled == null ? true : request.trackingEnabled,
			logLevel: "Debug"
		};

		log.info("Initializing devtodev %o", initData);
		(window as any).devtodev.initialize(devToDev.AppId, initData);
	}

	StatEvent(request: IFxIdSdkAdapterStatEventRequest): Promise<void> {
		const statEvent = this.fxIdStats.ProcessStat(request);

		const devToDev = this.config.Statistics?.DevToDev;
		if (devToDev == null) {
			log.warn("No devtodev configuration");
			return Promise.resolve(undefined);
		}

		if ((window as any).devtodev == null) {
			log.error("Dev2Dev not initialize - StatEvent will not be sent");
			return Promise.resolve(undefined);
		}

		// https://docs.devtodev.com/integration/integration-of-sdk/analytics-intergation/basic-methods
		if (statEvent.type === FxIdStatsTutorialStart) {
			(window as any).devtodev.tutorial(-1);
		} else if (statEvent.type === FxIdStatsTutorialCompleted) {
			(window as any).devtodev.tutorial(-2);
		} else if (statEvent.type === FxIdStatsTutorialSkip) {
			(window as any).devtodev.tutorial(0);
		} else if (statEvent.type === FxIdStatsTutorialStep) {
			(window as any).devtodev.tutorial(statEvent.event.value);
		}

		return Promise.resolve(undefined);
	}

	override AdsIsVideoReady() {
		window.FxIdSdk!.DispatchAdsVideoAvailable();
		return Promise.resolve(true);
	}

	override async AdsShowVideo() {
		try {
			const [data, error] = await this.sdk!.showCampaign("rewarded");
		} catch (err) {
			window.FxIdSdk!.DispatchAdsFailed();
			log.error(err);
		}
	}

	private async OnAgRuSdkOptionsUpdate(newOptions: AgRuSdkOptions) {
		// Update your SDK instance options.
		this.sdk!.options = { ...this.sdk!.options, ...newOptions };

		// Check if your local player id still the same.
		log.info("New player id: %s. Current player: %o", this.sdk!.options.player_id, this.currentPlayer);
		log.info(
			"New game sid: %s. Current player game sid: %o",
			this.sdk!.options.game_sid,
			this.currentPlayer?.game_sid
		);

		if (this.sdk!.options.player_id === this.currentPlayer!.id.toString()) {
			log.info("Player id has not changed. Doing nothing");
			// Обновление пользователя на всякий случай и не ждем
			await this.UpdateCurrenUserAndUserStore();
			return;
		}

		log.info("Player id has changed");

		if (newOptions.guest === "true") {
			log.info("Somehow new user is still a guest. Reloading.");
			document.location.reload();
		}

		const authenticationResult = await this.exportedSdk.AuthenticateApi({
			Game: this.game,
			Social: FxIdDomainStoreEnumsSupportedWebPublishingPlatform[
				FxIdDomainStoreEnumsSupportedWebPublishingPlatform.AbsoluteGames
			],
			AbsoluteGamesLogin: {
				QueryString: location.search,
				UserAuth: {
					AppId: this.sdk!.options.app_id!.toString(),
					AuthKey: this.sdk!.options.auth_key!.toString(),
					GameSid: this.sdk!.options.game_sid!.toString(),
					Guest: newOptions.guest === "true",
					PlayerId: this.sdk!.options.player_id!.toString()
				}
			}
		});

		if (authenticationResult.changeEvent != null) {
			log.info("Player changed");
		}
	}
}
